import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import AdminDonutChart from './component'

const AdminDonutChartContainer = ({
  data,
  isOptedInStudentActivities,
  navigationEvent,
  programName,
}) => {
  return (
    <AdminDonutChart
      data={data}
      isOptedInStudentActivties={isOptedInStudentActivities}
      navigationEvent={navigationEvent}
      programName={programName}
    />
  )
}

AdminDonutChartContainer.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      totalPracticesOrActivities: PropTypes.number.isRequired,
      totalPracticesOrActivitiesCompleted: PropTypes.number.isRequired,
      totalUsers: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
      grade10LessonsCompleted: PropTypes.number.isRequired,
      grade11LessonsCompleted: PropTypes.number.isRequired,
      grade12LessonsCompleted: PropTypes.number.isRequired,
      grade9LessonsCompleted: PropTypes.number.isRequired,
      totalUsers: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  isOptedInStudentActivities: PropTypes.bool,
  navigationEvent: PropTypes.func,
  programName: PropTypes.string.isRequired,
}

const mapDispatchToProps = {
  navigationEvent,
}

export default connect(null, mapDispatchToProps)(AdminDonutChartContainer)
