const subDomain = 'user-progress'

export const programMap = {
  'Educator Practices': {
    colors: ['#C62D70', '#CED8DF'],
    keys: [
      'totalPracticesOrActivitiesCompleted',
      'totalPracticesOrActivities',
      'totalUsers',
    ],
    url: `${subDomain}/educator-practices`,
  },
  'Schoolwide Practices': {
    colors: ['#002855', '#CED8DF'],
    keys: [
      'totalPracticesOrActivitiesCompleted',
      'totalPracticesOrActivities',
      'totalUsers',
    ],
    url: `${subDomain}/schoolwide-practices`,
  },
  'Student Activities': {
    colors: ['#C62D70', '#002855', '#5C5FB9', '#00847F', '#CED8DF'],
    keys: [
      'grade9LessonsCompleted',
      'grade10LessonsCompleted',
      'grade11LessonsCompleted',
      'grade12LessonsCompleted',
      'totalPracticesOrActivitiesCompleted',
      'totalUsers',
    ],
    url: `${subDomain}/student-activities`,
  },
}
